import * as React from "react"

import PropTypes from "prop-types"

import { Link } from "gatsby"

import { useState, useRef, useLayoutEffect } from "react"

import useWindowWidth from "../hooks/useWindowWidth"

import AnimatedHeading from "../components/AnimatedHeading"

import AnimatedBlock from "../components/AnimatedBlock"

import "../css/header.css"

const Header = ({ siteTitle, logoSrc }) => {
  const [height, setHeight] = useState("0px")
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const windowWidth = useWindowWidth()

  const localRef = useRef(null)

  useLayoutEffect(() => {
    setHeight(
      localRef.current?.firstElementChild.getBoundingClientRect().height + "px"
    )
  }, [localRef])

  return (
    <header
      ref={localRef}
      style={{
        height: height,
        transition: "all 0.3s ease 0.3s",
        top: "0px",
        left: "0px",
        zIndex: "10",
      }}
    >
      <ul className="kn-main-menu">
        <li>
          <AnimatedBlock
            animation={[
              {
                transform: "translate( -100px, -50px)",
                opacity: "0",
                transition: "all 0.3s ease",
              },
              {
                transform: "translate( 0, 0px)",
                opacity: "1",
                transition: "all 0.6s ease",
              },
            ]}
            delay={300}
          >
            <Link
              to="/"
              className="kn-logo-link"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen)
              }}
            >
              <img
                src={logoSrc}
                className="kn-logo"
                alt="logo"
                width="auto"
                height="80"
              />
            </Link>
          </AnimatedBlock>
        </li>

        {windowWidth > 1099 ? (
          <>
            <li>
              <AnimatedBlock
                animation={[
                  {
                    transform: "translate( 0px, -20px)",
                    opacity: "0",
                    transition: "all 0.3s ease",
                  },
                  {
                    transform: "translate( 0, 0px)",
                    opacity: "1",
                    transition: "all 0.6s ease",
                  },
                ]}
                delay={300}
              >
                <ul className="kn-info-header-list">
                  <li>
                    <AnimatedHeading
                      className="fas fa-envelope"
                      delay={350}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-info-header-link"
                      delay={450}
                      delayBetween={35}
                    >
                      <a href="mailto:ivan@geodioba.hr">ivan@geodioba.hr</a>
                    </AnimatedHeading>
                  </li>
                  <li>
                    <AnimatedHeading
                      className="fas fa-mobile-alt"
                      delay={450}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-kontakt-link"
                      delay={550}
                      delayBetween={35}
                    >
                      <a href="tel:+385 91 550 58 83">+385 91 550 58 83</a>
                    </AnimatedHeading>
                  </li>
                  <li>
                    <AnimatedHeading
                      className="fab fa-brands fa-facebook-square"
                      delay={550}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-kontakt-link"
                      delay={650}
                      delayBetween={35}
                    >
                      <a
                        href="https://www.facebook.com/112885214628442/posts/112899987960298/?sfnsn=mo"
                        aria-hidden="facebook"
                      >
                        @GeodiobaDoo
                      </a>
                    </AnimatedHeading>
                  </li>
                </ul>
              </AnimatedBlock>
            </li>
            <li>
              <AnimatedHeading className="" delay={333} delayBetween={35}>
                <Link
                  to="/"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Početna
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={600} delayBetween={35}>
                <Link
                  to="#Usluge"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Usluge
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={467} delayBetween={35}>
                <Link
                  to="#O-nama"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  O nama
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={733} delayBetween={35}>
                <Link
                  to="#Kontakt"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Kontakt
                </Link>
              </AnimatedHeading>
            </li>
          </>
        ) : (
          <>
            <li className="kn-navigation-toggle-item">
              <button
                className="kn-navigation-toggle-button"
                role="button"
                aria-label="Izbornik"
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
              >
                <i className="fas fa-bars"></i>
              </button>
            </li>
          </>
        )}
      </ul>
      {windowWidth <= 1099 ? (
        <div
          className={`kn-main-menu-mobile-container ${
            mobileMenuOpen ? "kn-main-menu-mobile-open" : ""
          }`}
        >
          <ul className="kn-main-menu-mobile-top">
            <li>
              <AnimatedBlock
                animation={[
                  {
                    transform: "translate( -100px, -50px)",
                    opacity: "0",
                    transition: "all 0.3s ease",
                  },
                  {
                    transform: "translate( 0, 0px)",
                    opacity: "1",
                    transition: "all 0.6s ease",
                  },
                ]}
                delay={300}
              >
                <Link
                  to="/"
                  className="kn-logo-link"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  <img
                    src={logoSrc}
                    className="kn-logo"
                    alt="logo"
                    width="auto"
                    height="80"
                  />
                </Link>
              </AnimatedBlock>
            </li>
            <li>
              <AnimatedBlock
                animation={[
                  {
                    transform: "translate( 0px, -20px)",
                    opacity: "0",
                    transition: "all 0.3s ease",
                  },
                  {
                    transform: "translate( 0, 0px)",
                    opacity: "1",
                    transition: "all 0.6s ease",
                  },
                ]}
                delay={300}
              >
                <ul className="kn-info-header-list">
                  <li>
                    <AnimatedHeading
                      className="fas fa-envelope"
                      delay={350}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-info-header-link"
                      delay={450}
                      delayBetween={35}
                    >
                      <a href="mailto:ivan@geodioba.hr">ivan@geodioba.hr</a>
                    </AnimatedHeading>
                  </li>
                  <li>
                    <AnimatedHeading
                      className="fas fa-mobile-alt"
                      delay={450}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-kontakt-link"
                      delay={550}
                      delayBetween={35}
                    >
                      <a href="tel:+385 91 550 58 83">+385 91 550 58 83</a>
                    </AnimatedHeading>
                  </li>
                  <li>
                    <AnimatedHeading
                      className="fab fa-brands fa-facebook-square"
                      delay={550}
                      delayBetween={35}
                    >
                      <i aria-hidden="true"></i>
                    </AnimatedHeading>
                    <AnimatedHeading
                      className="kn-kontakt-link"
                      delay={650}
                      delayBetween={35}
                    >
                      <a
                        href="https://www.facebook.com/112885214628442/posts/112899987960298/?sfnsn=mo"
                        aria-hidden="facebook"
                      >
                        @GeodiobaDoo
                      </a>
                    </AnimatedHeading>
                  </li>
                </ul>
              </AnimatedBlock>
            </li>

            <li className="kn-navigation-toggle-item">
              <button
                className="kn-navigation-toggle-button-close"
                role="button"
                aria-label="zatvori izbornik"
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </li>
          </ul>
          <ul className="kn-main-menu-mobile">
            <li>
              <AnimatedHeading className="" delay={333} delayBetween={35}>
                <Link
                  to="/"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Početna
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={600} delayBetween={35}>
                <Link
                  to="#Usluge"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Usluge
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={467} delayBetween={35}>
                <Link
                  to="#O-nama"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  O nama
                </Link>
              </AnimatedHeading>
            </li>
            <li>
              <AnimatedHeading className="" delay={733} delayBetween={35}>
                <Link
                  to="#Kontakt"
                  onClick={() => {
                    setMobileMenuOpen(!mobileMenuOpen)
                  }}
                >
                  Kontakt
                </Link>
              </AnimatedHeading>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  logoSrc: PropTypes.string.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
