import * as React from "react"

import { useEffect, useState, useRef} from "react"

import useOnScreen from "../hooks/useOnScreen"

import PropTypes from "prop-types"

import "../css/animatedBlock.css"


const AnimatedBlock = ({ className, delay, animation, children }) => {
	
	animation[0].transition += " " + parseFloat(delay) + "ms"
	animation[1].transition += " " + parseFloat(delay) + "ms"
	
	const [ contentPosition, setContentPosition] = useState( { ...animation[0]})
	
	const ref = useRef()
	
	const sectionVisible = useOnScreen(ref)
	
	useEffect( () => {
		
		if( sectionVisible)
			setContentPosition( { ...animation[1]})

	},[ sectionVisible, animation])
	
	return (
		<div ref={ ref} style={ contentPosition} className={`kn-animated-block ${className}`} >
			{children}
		</div>
	)
}

AnimatedBlock.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  animation: PropTypes.array,
  children: PropTypes.node.isRequired,
}

AnimatedBlock.defaultProps = {
  className: ``,
  delay: 0,
  animation: [ { top: "0", left: "0", transform: "translate( 0, 50px)", opacity: "0", transition: "all 0.7s ease"}, { top: "0", left: "0", transform: "translate( 0, 0px)", opacity:"1", transition: "all 0.7s ease"}],
}

export default AnimatedBlock
