import React from "react"

import PropTypes from "prop-types"

import { useState, useRef, useLayoutEffect } from "react"

import emailjs from "emailjs-com"

import ReCAPTCHA from "react-google-recaptcha"

import useOnScreen from "../hooks/useOnScreen"
import useWindowWidth from "../hooks/useWindowWidth"

import AnimatedHeading from "./AnimatedHeading"

import "../css/contactSection.css"

const ContactSection = ({ id }) => {
  const ref = useRef()
  const formRef = useRef()

  const sectionVisible = useOnScreen(ref)

  const [subject, setSubject] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [recaptcha, setRecaptcha] = useState("")

  const [formStyle, setFormStyle] = useState({})

  const [formVisible, setFormVisible] = useState(true)
  const [formSent, setFormSent] = useState(false)

  const windowWidth = useWindowWidth()

  useLayoutEffect(() => {
    if (
      window.getComputedStyle === "undefined" ||
      formRef.current?.firstElementChild
    )
      return
    let height =
      formRef.current?.getBoundingClientRect().height +
      parseInt(
        window
          .getComputedStyle(formRef.current?.firstElementChild)
          .getPropertyValue("padding-top")
      ) +
      parseInt(
        window
          .getComputedStyle(
            formRef.current?.lastElementChild.previousElementSibling
          )
          .getPropertyValue("padding-bottom")
      )

    if (windowWidth <= 991) height += 78

    setFormStyle({ height: height })
  }, [windowWidth])

  const sendEmail = e => {
    e.preventDefault()

    const params = {
      subject: subject,
      name: name,
      email: email,
      message: message,
      "g-recaptcha-response": recaptcha,
    }

    emailjs
      .send(
        process.env.GATSBY_EMAIL_JS_SERVICE,
        process.env.GATSBY_EMAIL_JS_TEMPLATE,
        params,
        process.env.GATSBY_EMAIL_JS_USER
      )
      .then(
        result => {
          setFormSent(true)
          setFormStyle({ height: 0 })
        },
        error => {}
      )
  }

  const finalizeForm = e => {
    if (e.propertyName === "height") setFormVisible(false)
  }

  const contactForm = formVisible && (
    <form
      style={formStyle}
      ref={formRef}
      className={`kn-contact-form${formSent ? " kn-contact-form-sent" : ""}`}
      onTransitionEnd={finalizeForm}
    >
      <div className="kn-contact-section-form-group">
        <input
          type="text"
          id="kn-contact-subject"
          placeholder=" "
          value={subject}
          name="subject"
          onChange={event => {
            let val = event.target.value
            setSubject(val)
          }}
          required
        />
        <label htmlFor="kn-contact-subject">Naslov</label>
      </div>

      <div className="kn-contact-section-form-group">
        <input
          type="text"
          id="kn-contact-name"
          placeholder=" "
          value={name}
          name="name"
          onChange={event => {
            let val = event.target.value
            setName(val)
          }}
          required
        />
        <label htmlFor="kn-contact-name">Ime i prezime</label>
      </div>

      <div className="kn-contact-section-form-group">
        <input
          type="email"
          id="kn-contact-email"
          name="email"
          value={email}
          placeholder=" "
          onChange={event => {
            let val = event.target.value
            setEmail(val)
          }}
          required
        />
        <label htmlFor="kn-contact-email">Email</label>
      </div>

      <div className="kn-contact-section-form-group kn-contact-section-form-group-textarea">
        <textarea
          id="kn-contact-message"
          name="message"
          placeholder=" "
          rows="4"
          value={message}
          onChange={event => {
            let val = event.target.value
            setMessage(val)
          }}
          required
        ></textarea>
        <label htmlFor="kn-contact-message">Upit</label>
      </div>

      <div className="kn-contact-section-form-group kn-contact-section-form-group-captcha">
        {process.env.GATSBY_CAPTCHA_SITE_KEY && (
          <ReCAPTCHA
            sitekey={process.env.GATSBY_CAPTCHA_SITE_KEY}
            onChange={event => {
              setRecaptcha(event)
            }}
            hl="hr"
          />
        )}
      </div>

      <div className="kn-contact-section-form-group kn-contact-section-form-group-button">
        <input type="submit" id="kn-contact-submit" value="Pošalji" />
      </div>
    </form>
  )

  return (
    <div
      id={id}
      ref={ref}
      className={`kn-contact-section${
        sectionVisible && !formSent ? " kn-section-visible" : ""
      }${formSent ? " kn-section-form-sent" : ""}`}
      onSubmit={sendEmail}
    >
      <div className="kn-contact-title-container">
        <AnimatedHeading
          className="kn-footer-title"
          delay={550}
          delayBetween={35}
        >
          <h3>{!formSent ? `Kontaktirajte nas` : ` Zahvaljujemo na upitu`}</h3>
        </AnimatedHeading>
      </div>

      <div className="kn-contact-outer-section">{contactForm}</div>
    </div>
  )
}

ContactSection.propTypes = {
  id: PropTypes.string,
}

ContactSection.defaultProps = {
  id: ``,
}

export default ContactSection
