
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Footer from "./Footer"
import "../css/layout.css"

import CookieConsent from 'react-cookie-consent';
import AniLink from "gatsby-plugin-transition-link/AniLink"

import logo from "../images/logo.png"
import koonaLogo from "../images/koona-logo.png"



const Layout = ( { className, children}) => {
		
	const { site } = useStaticQuery( 
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	)
	
	return (
		<div className={ className}>
			<Header siteTitle={ site.siteMetadata?.title || `Title`} logoSrc={ logo} />
			<div>
				<main >{children}</main>
			</div>
			<Footer logoSrc={ logo} koonaLogo={ koonaLogo}  />
			<CookieConsent location="bottom" buttonText="Prihvaćam" declineButtonText="Odbijam" cookieName="gatsby-gdpr-google-analytics">
				IZJAVA O ZAŠTITI PRIVATNOSTI I SIGURNOSTI OSOBNIH PODATAKA (<AniLink fade to="/pravila-privatnosti"> Saznajte više</AniLink>)
			</CookieConsent>
		</div>
	)
}

Layout.propTypes = {	
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
	className:``
}

export default Layout
