import * as React from "react"

import PropTypes from "prop-types"

import { Link } from "gatsby"

import "../css/footer.css"

import AnimatedHeading from "../components/AnimatedHeading"

import AnimatedBlock from "../components/AnimatedBlock"

import ContactSection from "../components/ContactSection"

const Footer = ({ logoSrc, koonaLogo}) => {
	
	return (
		<footer className="kn-footer" >
			<div className="kn-footer-column-full-wrapper">
				<div className="kn-footer-column-full">
					<AnimatedBlock className="kn-footer-contact-form" delay={200} delayBetween={35}>
						<ContactSection id="kontakt" />
					</AnimatedBlock>
					<AnimatedBlock animation={[ { transform: "translate( -100px, 0px)", opacity: "0", transition: "all 0.6s ease"}, { transform: "translate( 0px, 0px)", opacity:"1", transition: "all 0.6s ease"}]} delay={50}>
							<Link to="/"><img src={ logoSrc} alt="logo" width="auto" height="80" /></Link>
					</AnimatedBlock>
					
					<AnimatedBlock className="kn-fourth-section-paragraph-ab" delay={175} animation={ [{transform: "translate( 0px, -25px)", opacity: "0", transition: "all 0.8s ease, opacity 0.5s 0.2s ease", padding: "15px 50px"}, {transform: "translate( 0, 0px)", opacity:"1", transition: "all 0.8s ease, opacity 0.5s 0.2s ease", padding: "15px 50px"}]}>
							<hr className="kn-block-services-divider"/>
					</AnimatedBlock>
				</div>
			</div>
			<div className="kn-footer-column kn-footer-column-first">
				<ul className="kn-footer-info">
					<li>
						<AnimatedHeading className="kn-footer-info-pre" delay={250} delayBetween={35}><h4>Vlasnik:</h4></AnimatedHeading>
						<AnimatedHeading className="kn-footer-info-content" delay={350} delayBetween={35}><h4>Ivan Josić mag.ing.geod. et geoinf., direktor</h4></AnimatedHeading>
					</li>
					<li>
						<AnimatedHeading className="kn-footer-info-pre" delay={350} delayBetween={35}><h4>OIB:</h4></AnimatedHeading>
						<AnimatedHeading className="kn-footer-info-content" delay={450} delayBetween={35}><h4>69115179421</h4></AnimatedHeading>
					</li>
					<li>
						<AnimatedHeading className="kn-footer-info-pre" delay={450} delayBetween={35}><h4>Adresa:</h4></AnimatedHeading>
						<AnimatedHeading className="kn-footer-info-content" delay={550} delayBetween={35}><h4>Ostrovo 78, Topolovac, 44000 Sisak</h4></AnimatedHeading>
					</li>					
					<li>
						<AnimatedHeading className="kn-footer-info-pre" delay={450} delayBetween={35}><h4>Temeljni kapital:</h4></AnimatedHeading>
						<AnimatedHeading className="kn-footer-info-content" delay={550} delayBetween={35}><h4>20.000 kn</h4></AnimatedHeading>
					</li>
				</ul>
			</div>
			<div className="kn-footer-column kn-footer-column-second">
				<ul className="kn-footer-menu">
					<li><AnimatedHeading  className="" delay={300} delayBetween={25}><Link to="/#Početna">Povratak na vrh</Link></AnimatedHeading></li>
					<li><AnimatedHeading  className="" delay={400} delayBetween={25}><Link to="/#O nama">O nama</Link></AnimatedHeading></li>
					<li><AnimatedHeading  className="" delay={500} delayBetween={25}><Link to="/#Usluge">Usluge</Link></AnimatedHeading></li>
					<li><AnimatedHeading  className="" delay={600} delayBetween={25}><Link to="/#Kontakt">Kontakt</Link></AnimatedHeading></li>
					<li><AnimatedHeading  className="" delay={600} delayBetween={25}><Link to="/pravila-privatnosti">Pravila privatnosti</Link></AnimatedHeading></li>
				</ul>
				
			</div>
			
			<div className="kn-footer-column-full-wrapper">
				<div className="kn-footer-column-designed-by-koona">
					<AnimatedHeading className="kn-designed-by-koona-text" delay={700} delayBetween={25}><p>Designed by </p></AnimatedHeading>
					<AnimatedBlock animation={[ { transform: "translate( 0px, 50px)", opacity: "0", transition: "all 0.4s ease"}, { transform: "translate( 0px, 0px)", opacity:"1", transition: "all 0.4s ease"}]} delay={50}>
							<Link to="https://koona.tech">
								<img className="kn-designed-by-koona-logo" src={ koonaLogo} alt="Koona Tech logo" width="auto" height="40" />
							</Link>
					</AnimatedBlock>
				</div>
			</div>
		</footer>
)
}

Footer.propTypes = {
	logoSrc: PropTypes.string.isRequired,
	koonaLogo: PropTypes.string.isRequired,
}

Footer.defaultProps = {
}

export default Footer
